<template>
  <div>
    <hero></hero>
    <faq></faq>
     <l-footer></l-footer>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>